// SPDX-FileCopyrightText: 2021 - 2022 Dusan Mijatovic (dv4all)
// SPDX-FileCopyrightText: 2021 - 2022 dv4all
// SPDX-FileCopyrightText: 2022 - 2023 Christian Meeßen (GFZ) <christian.meessen@gfz-potsdam.de>
// SPDX-FileCopyrightText: 2022 - 2023 Helmholtz Centre Potsdam - GFZ German Research Centre for Geosciences
// SPDX-FileCopyrightText: 2022 Jesús García Gonzalez (Netherlands eScience Center) <j.g.gonzalez@esciencecenter.nl>
// SPDX-FileCopyrightText: 2022 Marc Hanisch (GFZ) <marc.hanisch@gfz-potsdam.de>
// SPDX-FileCopyrightText: 2022 Netherlands eScience Center
//
// SPDX-License-Identifier: Apache-2.0
// SPDX-License-Identifier: EUPL-1.2

/* eslint-disable @next/next/no-img-element */
import React, {useEffect, useState, useRef} from 'react'
import AOS from 'aos'
import AppHeader from '~/components/AppHeader'
import AppFooter from '~/components/AppFooter'
import 'simplebar/dist/simplebar.min.css'

import {OrganisationForOverview} from '~/types/Organisation'

/*! purgecss start ignore */
import 'aos/dist/aos.css'
import {createJsonHeaders} from '~/utils/fetchHelpers'
import logger from '~/utils/logger'
import SpotlightSection from './SpotlightSection'
import useLoginProviders from '~/auth/api/useLoginProviders'
import {useAuth} from '~/auth'
import HgfNewsSection from './news/HgfNewsSection'
import ParticipatingOrganisationsSection from './ParticipatingOrganisationsSection'
import ResearchFieldSection from './ResearchFieldSection'
import AddYourSoftwareSection from './AddYourSoftwareSection'
import ClaimSection from './ClaimSection'
import {NewsListItem} from '~/components/news/apiNews'
import {SpotlightDescription} from './LatestSpotlight'
/*! purgecss end ignore */

type HgfNewsProps = {
  hgfNews?: NewsListItem[]
}

export default function Home({hgfNews}: HgfNewsProps) {
  const [organisations, setOrganisations] = useState<OrganisationForOverview[]>([])
  const [spotlight, setSpotlight] = useState<SpotlightDescription | undefined>(undefined)
  const simplebarRef = useRef()
  const providers = useLoginProviders()
  const {session} = useAuth()
  const status = session?.status || 'loading'

  useEffect(() => {
    // Initialize AOS library
    AOS.init()

    // create ref for simplebar and recalculate
    // https://github.com/Grsmto/simplebar/tree/master/packages/simplebar#notifying-the-plugin-of-content-changes
    const sb: any = simplebarRef.current
    if (sb && 'recalculate' in sb) {
      sb.recalculate()
      // The following lines are a workaround to set the width of the scrollbar
      // to 33% of the container width. The resizing works when building the
      // page the first time. This method does not correct the size of the
      // scrollbar after resizing the window.
      const trackWidth = sb.axis['x'].track.el[sb.axis['x'].offsetSizeAttr]
      const scrollbarWidth = Math.round(trackWidth*0.33)
      sb.axis.x.scrollbar.el.style.width = scrollbarWidth + 'px'
    }
  }, [])

  useEffect(() => {
    async function getData () {
      const url = '/api/v1/rpc/organisations_overview?parent=is.null&software_cnt=gt.0&is_tenant=eq.true'
      const data = await getOrganisationsList({url})
      setOrganisations(data)
    }
    getData()
  }, [])

  useEffect(() => {
    async function getSpotlights() {
      const data = await getLatestSpotlight()
      if (typeof data === 'object' && 'image_id' in data) {
        setSpotlight(data)
      }
    }
    getSpotlights()
  }, [])

  const handleClickAddSoftware = () => {
    let getStartedHref:string
    if (status !== 'authenticated') {
      getStartedHref = providers[0]?.redirectUrl ?? ''
      if (typeof document !== 'undefined' ) {
        document.cookie = `rsd_pathname=${location.href}software/add;path=/auth;SameSite=None;Secure`
      }
    } else {
      getStartedHref = '/software/add'
    }
    window.location.href = getStartedHref
  }

  return (
    <div className="bg-base-100" data-testid="rsd-helmholtz-home">

      <AppHeader/>

      <ClaimSection />

      {/* Top news items, ONLY if there are some */}
      <HgfNewsSection news={hgfNews} />

      {/* Software spotlights */}
      <SpotlightSection spotlight={spotlight} />

      <AddYourSoftwareSection handleClickAddSoftware={handleClickAddSoftware} />

      <ResearchFieldSection />

      <ParticipatingOrganisationsSection
        organisations={organisations}
        simplebarRef={simplebarRef}
        handleClickAddSoftware={handleClickAddSoftware}
      />

      <AppFooter/>
    </div>
  )
}

async function getOrganisationsList({url, token}: {url: string, token?: string}) {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        ...createJsonHeaders(token),
      },
    })

    if ([200, 206].includes(resp.status)) {
      const organisationList: OrganisationForOverview[] = await resp.json()

      const shuffled_data = []
      while (organisationList.length > 0) {
        let rnd = Math.floor(Math.random() * (organisationList.length))
        shuffled_data.push(organisationList.splice(rnd, 1)[0])
      }

      return shuffled_data
    }
    // otherwise request failed
    logger(`getOrganisationsList failed: ${resp.status} ${resp.statusText}`, 'warn')
    // we log and return zero
    return []
  } catch (e: any) {
    logger(`getOrganisationsList: ${e?.message}`, 'error')
    return []
  }
}

async function getLatestSpotlight() {
  try {
    const resp = await fetch('/api/v1/rpc/highlight_overview?select=slug,image_id,short_statement,brand_name,position&order=position&limit=1', {method: 'GET'})

    if ([200, 206].includes(resp.status)) {
      const latestSpotlight: SpotlightDescription[] = await resp.json()
      return latestSpotlight[0]
    }
    // otherwise request failed
    logger(`getLatestSpotlight failed: ${resp.status} ${resp.statusText}`, 'warn')
    // we log and return zero
    return []
  } catch (e: any) {
    logger(`getLatestSpotlight: ${e?.message}`, 'error')
    return []
  }
}
